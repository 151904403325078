<template>
  <hub-form ref="hubForm" :messages="messages" :onSubmit="savePassword">
    <template v-slot:formcontent>
      <h3>Enter a password to finish your registration and to get access to your bookings, scores en photos!</h3>
      <input placeholder="password" type="password" v-model="model.password" maxLength="32" minLength="5" required />
      <input placeholder="confirm password" type="password" v-model="model.confirmPassword" maxLength="32" minLength="5"
        required />
      <button @submit.prevent>Confirm password</button>
      <router-link to="/login">
        Back to login
      </router-link>
    </template>
  </hub-form>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import api from "../../api/api";
import MESSAGE_TYPES from "../../constants/messageTypes";
import HubForm from "../shared/Form.vue";

const router = useRouter();
const route = useRoute();
const hubForm = ref(null);

const token = route.params.token;
const messages = {
    "200-299": {
        text: "Successfully registered account",
        type: MESSAGE_TYPES.success,
    },
    404: {
        text: "Token not found, please contact support.",
        type: MESSAGE_TYPES.error,
    },
    409: {
        text: "Account already confirmed. Please go to login",
        type: MESSAGE_TYPES.error,
    },
    default: {
        text: "Server error please try again later",
        type: MESSAGE_TYPES.error,
    },
};

const model = ref({
    password: "",
    confirmPassword: "",
});

const savePassword = async () => {
    hubForm.value.messages["404"] = {
        text: "Token not found, please contact support.",
        type: MESSAGE_TYPES.error,
    };
    hubForm.value.defaultError = "Registration failed, please contact support.";

    if (model.value.password !== model.value.confirmPassword) {
        hubForm.value.error("Passwords do not match");
    } else {
        const result = await api.request(
            "/clients/confirm",
            { method: "POST" },
            { token, password: model.value.password },
        );
        hubForm.value.res = result.status;

        console.info("RES STATUS:", result.status);

        if (result.status >= 200 && result.status <= 299) {
            const res = await result.json();
            const jwt = res.jwt;
            router.push({ name: "booking", params: { jwt } });
        }
    }
};

onMounted(async () => {
    const isVerified = await api.checkConfirmToken(token);
    if (isVerified) {
        router.push({
            name: "booking",
        }); /* If not logged in, will route to login page*/
    }
});
</script>

<style scoped></style>
