<template>
    <div class="bookingtable-wrapper" v-if="locations.length > 0 && experiences.length > 0">
        <booking-filters :locations="locations" :experiences="experiences" :playerSum="totalPlayers"
            :averageRating="averageRating" :averageLocationRating="averageLocationRating"
            :averagePlayingTime="averagePlayingTime" />
        <div v-if="!loading" class="bookingtable-container">
            <div class="table-tabs">
                <button :class="`tab next-bookings ${!past ? 'selected' : ''
                    }`" @click="() => selectTable(false)">
                    Upcoming Bookings ({{ nextBookings.length }})
                </button>
                <button :class="`tab past-bookings ${past ? 'selected' : ''
                    }`" @click="() => selectTable(true)">
                    Past Bookings ({{ lastBookings.length }})
                </button>
            </div>
            <div class="tables">
                <booking-table :class="`booking-table ${!past ? 'selected' : ''
                    }`" :bookings="nextBookings" />
                <booking-table :class="`booking-table ${past ? 'selected' : ''
                    }`" :bookings="lastBookings" />
            </div>
        </div>
        <spinner :loading="loading" text="Loading bookings..."></spinner>
    </div>
</template>

<script setup>
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, defineProps, ref, watch } from "vue";
import { useRouter } from "vue-router/composables";
import api from "../../api/api";
import useFilterStore from "../../store/filterStore";
import Spinner from "../shared/Spinner.vue";
import BookingFilters from "./Filters.vue";
import BookingTable from "./Table.vue";

const router = useRouter();
const filterStore = useFilterStore();

const { filters } = storeToRefs(filterStore);

const props = defineProps({
    locations: {
        type: Array,
        default: () => [],
    },
    experiences: {
        type: Array,
        default: () => [],
    },
    past: {
        type: Boolean,
        default: false,
    },
});

const nextBookings = ref([]);
const lastBookings = ref([]);
const loading = ref(false);

const averageRating = computed(() => {
    const { total, players } = lastBookings.value.reduce(
        (acc, booking) => {
            if (booking.rating) {
                return {
                    total: acc.total + booking.rating * booking.player_count,
                    players: acc.players + booking.player_count,
                };
            }
            return acc;
        },
        { total: 0, players: 0 },
    );

    return players > 0 ? total / players : null;
});

const averageLocationRating = computed(() => {
    const { total, players } = lastBookings.value.reduce(
        (acc, booking) => {
            if (booking.location_rating) {
                return {
                    total: acc.total + booking.location_rating * booking.player_count,
                    players: acc.players + booking.player_count,
                };
            }
            return acc;
        },
        { total: 0, players: 0 },
    );

    return players > 0 ? total / players : null;
});

const averagePlayingTime = computed(() => {
    const bookingsWithTime = lastBookings.value.filter((booking) => booking.minutes_played);
    if (bookingsWithTime.length === 0) {
        return null;
    }
    const totalTime = bookingsWithTime.reduce((acc, booking) => acc + booking.minutes_played, 0);
    return Math.round(totalTime / bookingsWithTime.length);
});

const totalPlayers = computed(() => {
    return [...nextBookings.value, ...lastBookings.value].reduce(
        (acc, booking) => acc + booking.player_count,
        0,
    );
});

const selectTable = (past) => {
    router.push({ query: { past } });
};

const filterBookings = async () => {
    if (!filters.value) {
        return;
    }
    const startDate = filters.value.startDate || new Date(0);
    const endDate = filters.value.endDate || new Date(2999, 0, 1);

    const newFilters = {
        include_deleted:
            filters.value.options.includes("Only deleted") ||
            filters.value.options.includes("Include deleted") ||
            null, // 2024-07-15 replace false by null to make the api work (controllers/booking.js#16)
        date_lte: dayjs(endDate).endOf("day").toISOString(),
        date_gt: dayjs(startDate).startOf("day").toISOString(),
        _limit: filters.value.limit,
        _sort: "date:desc",
        demo: false,
    };
    if (filters.value.options.includes("Demo")) {
        newFilters.demo = true;
    }
    if (filters.value.options.includes("Consent To Share")) {
        newFilters.consent_to_share = true;
    }
    if (filters.value.xCubeId !== "all") {
        newFilters["xcube.id"] = filters.value.xCubeId;
    }
    if (filters.value.locationId !== "all") {
        newFilters["xcube.location.id"] = filters.value.locationId;
    }

    if (filters.value.experienceId !== "all") {
        newFilters["experience.id"] = filters.value.experienceId;
    }

    try {
        loading.value = true;
        const bookings = (await api.getBookingTable(newFilters))
            .map((booking) => {
                const options = [];
                if (booking.deleted_at != null) {
                    options.push("Deleted");
                }
                if (booking.refund_requested) {
                    options.push("Refund");
                }
                if (booking.voucher_requested) {
                    options.push("Voucher");
                }
                if (booking.demo) {
                    options.push("Demo");
                }
                if (booking.consent_to_share) {
                    options.push("Consent To Share");
                }
                return { ...booking, options };
            })
            .filter((booking) => {
                const options = filters.value.options.filter((o) => !o.includes("deleted"));
                if (filters.value.options.includes("Only deleted")) {
                    options.push("Deleted");
                }
                return options.every((option) => booking.options.includes(option));
            });

        lastBookings.value = bookings.filter((booking) => new Date(booking.date) < new Date());
        nextBookings.value = bookings
            .filter((booking) => new Date(booking.date) > new Date())
            .reverse();
        loading.value = false;
    } catch (e) {
        loading.value = false;
        alert(e.message);
    } finally {
        loading.value = false;
    }
};

watch(filters, () => {
    filterBookings();
});

// Initial load
filterBookings();
</script>

<style scoped lang="scss">
.bookingtable-wrapper {
    position: absolute;
    left: 2vw;
    top: 2vh;
    width: calc(100% - 4vw);
    height: calc(100% - 4vh);
}

.bookingtable-container {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.table-tabs {
    display: flex;
    justify-content: space-between;

    .tab {
        width: 50%;

        background-color: #000000b5;
        color: #e8de16;

        border: 1px solid #e8de16;
        border-radius: 10px;

        padding: 5px;

        &.selected {
            background-color: #e8de16;
            color: black;
            font-weight: bold;
        }

        &:nth-child(1) {
            border-radius: 10px 0 0 0;
        }

        &:nth-child(2) {
            border-radius: 0 10px 0 0;
        }
    }
}

.tables {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1rem;

    .booking-table {
        // flex: 1 1 320px;

        border: 1px solid #e8de16;

        &:not(.selected) {
            display: none;
        }

        &:nth-child(1) {
            border-radius: 0 0 0 10px;
        }

        &:nth-child(2) {
            border-radius: 0 0 10px 0;
        }
    }
}

.page {
    width: 100%;
}

.spinner {
    padding: 4rem;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 320px) {
    .table-tabs {
        font-size: 4vw;
    }
}

@media only screen and (max-width: 768px),
(min-device-width: 1024px) and (max-device-width: 1440px) {
    .table-tabs {
        &.active {
            display: flex;

            .selected {
                background-color: #ffffff30;
            }
        }

        &.inactive {
            display: none;
        }
    }

    .tables [data-tab] {
        display: none;

        &.selected {
            display: block;
        }
    }
}

@media (min-device-width: 1440px) and (max-device-width: 1872px) {
    .tables {
        font-size: 0.85vw;
    }
}
</style>