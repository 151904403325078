<template>
    <div class="booking-create">
        <div v-if="loading">
            <center>Creating booking...</center>
        </div>

        <hub-form
            ref="formRef"
            :onSubmit="saveBooking"
            class="width-90-create center-content"
        >
            <template #formcontent>
                <el-form label-width="160px">
                    <div v-if="createdBooking">
                        <h3 class="text-center">Booking created</h3>
                        <show-booking
                            :booking="createdBooking"
                            class="width-90-create"
                        />
                    </div>

                    <template v-else>
                        <div class="form-wrapper">
                            <span>
                                <h3 class="text-center">Create booking</h3>
                                <el-form-item label="Name">
                                    <el-input
                                        placeholder="John Doe"
                                        v-model="booking.name"
                                        required
                                    />
                                </el-form-item>
                                <el-form-item label="Email">
                                    <el-input
                                        placeholder="john@doe.com"
                                        type="email"
                                        v-model="booking.email"
                                        required
                                    />
                                </el-form-item>
                                <el-form-item label="Teamname">
                                    <el-input
                                        placeholder="Team Fam Doe"
                                        v-model="booking.team_1_name"
                                        required
                                    />
                                </el-form-item>
                                <el-form-item label="Language">
                                    <el-select v-model="booking.language" required>
                                        <el-option
                                            v-for="language of [
                                                'nl',
                                                'de',
                                                'en',
                                                'fr',
                                            ]"
                                            :key="language"
                                            :value="language"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Amount of Players">
                                    <el-select v-model="booking.team_1_size">
                                        <el-option
                                            v-for="i of [1, 2, 3, 4, 5, 6]"
                                            :value="i"
                                            :key="i"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Event Date">
                                    <div class="input-with-text">
                                        <date-picker
                                            v-model="booking.event_date"
                                            :confirm="true"
                                            type="date"
                                            format="DD-MM-YYYY"
                                            placeholder="Select date"
                                            required
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item label="Event Time">
                                    <div class="input-with-text">
                                        <date-picker
                                            v-model="booking.event_time"
                                            :confirm="true"
                                            type="time"
                                            format="HH:mm"
                                            placeholder="Select time"
                                            required
                                        />
                                    </div>
                                    <span class="text-next-to-input"
                                        >Timezone: {{ getTimezoneName() }}</span
                                    >
                                </el-form-item>

                                <el-form-item
                                    v-if="isOperator || isManagement"
                                    label="X-Cube"
                                >
                                    <el-select
                                        v-model="booking.xcube_id"
                                        placeholder="X-Cube"
                                        filterable
                                        required
                                    >
                                        <el-option-group
                                            v-for="location in locations"
                                            :key="location.id"
                                            :label="location.name"
                                        >
                                            <el-option
                                                v-for="xcube in location.xcubes"
                                                :key="xcube.id"
                                                :value="xcube.id"
                                                :label="`${xcube.letter} - ${xcube.name}`"
                                            />
                                        </el-option-group>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Experience">
                                    <el-select
                                        v-model="booking.experience_id"
                                        placeholder="Experience"
                                        required
                                    >
                                        <el-option
                                            v-for="experience in experiences"
                                            :key="experience.id"
                                            :label="experience.name"
                                            :value="experience.id"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-button @click="saveBooking"
                                        >Submit</el-button
                                    >
                                </el-form-item>
                            </span>
                        </div>
                    </template>
                </el-form>
            </template>
        </hub-form>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";
import { computed, onMounted, ref } from "vue";
import DatePicker from "vue2-datepicker";
import api from "../../api/api";
import {
    isManagement as determineIsManagement,
    isOperator as determineIsOperator,
} from "../../helpers/auth";
import useCurrentUserStore from "../../store/userStore";
import HubForm from "../shared/Form.vue";
import ShowBooking from "./Show.vue";

const store = useCurrentUserStore();

const props = defineProps({
    locations: {
        type: Array,
        default: [],
    },
});

const formRef = ref(null);
const loading = ref(false);
const createdBooking = ref(null);
const experiences = ref([]);

const createEmptyBooking = () => {
    let uuid = new Date().getTime();
    if (self.crypto.randomUUID) {
        uuid = self.crypto.randomUUID();
    }
    const code = `HUB-${uuid}`;
    return {
        code,
        name: "",
        email: "",
        team_1_name: "",
        team_1_size: 1,
        language: "nl",
        event_date: "",
        event_time: "",
        xcube_id: null,
    };
};

const booking = ref(createEmptyBooking());

onMounted(async () => {
    if (props.locations.length === 1) {
        booking.value.location_id = props.locations[0].id;
    }
    experiences.value = await api.getExperiences();
});

const isManagement = computed(() => determineIsManagement(store.currentUser));
const isOperator = computed(() => determineIsOperator(store.currentUser));

const getTimezoneName = () => {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
        Sentry.captureException(error);
        return "Europe/Amsterdam";
    }
};

const saveBooking = async () => {
    const time = dayjs(booking.value.event_time);
    booking.value.event_datetime = dayjs(booking.value.event_date)
        .hour(time.hour())
        .minute(time.minute())
        .toDate();
    try {
        loading.value = true;
        const newBooking = await api.createBooking(booking.value);
        const data = await api.getBooking(newBooking.id);
        createdBooking.value = data;
        formRef.value.success("Booking created");
        booking.value = createEmptyBooking();
    } catch (e) {
        console.log("FORMREF", formRef.value);
        formRef.value.error(e.message);
        Sentry.captureException(e);
    } finally {
        loading.value = false;
    }
};

const formatReceivedBooking = (bookingData) => {
    const formattedBooking = { ...bookingData };
    if (formattedBooking.event_date) {
        formattedBooking.event_date = new Date(formattedBooking.event_date);
    }
    if (formattedBooking.event_time) {
        const [hours, minutes] = formattedBooking.event_time.split(":");
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        formattedBooking.event_time = date;
    }
    return formattedBooking;
};

const toggleDatePickerTable = () => {
    const datePickerTable = document.querySelector(".mx-table");
    if (datePickerTable && !datePickerTable.hasAttribute("hidden")) {
        datePickerTable.setAttribute("hidden", "");
    }
};
</script>

<style lang="scss">
@use "../../colors" as *;

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    form:not(.el-form) {
        width: max(50%, 750px);
    }
    form.el-form {
        width: 100%;
    }
}
.text-next-to-input {
    color: $color-primary;
    text-align: left;
    display: block;
}

.el-select {
    width: 100%;
}

.input-with-text {
    display: flex;
}

@media screen and (max-width: 800px) {
    .form-container {
        form:not(.el-form) {
            width: 90%;
        }
    }
    .el-form-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .el-form-item__label {
        text-align: center !important;
    }

    .el-form-item__content {
        margin-left: 0px !important;
        width: min(95%, 400px);
    }

    .el-select {
        width: 100%;
    }

    .input-with-text {
        justify-content: center;
    }
    .text-next-to-input {
        text-align: center;
    }
}
</style>