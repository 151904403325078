<template>
    <div v-if="locations">
        <div class="header">
            <h1>
                Site Information
                <span v-if="locations.length === 1">{{
                    locations[0].name
                }}</span>
            </h1>
            <div class="location-select" v-if="locations.length > 1">
                <v-select v-model="selectedLocation" :options="locations" :clearable="false" label="name"></v-select>
            </div>
        </div>
        <div v-if="selectedLocation">
            <div v-if="selectedContentBlocks?.length > 0">
                <render-content-blocks :content-blocks="selectedContentBlocks" />
            </div>
            <div v-else>
                <p>No specific information for {{ selectedLocation.name }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import api from "../../api/api";
import useCurrentUserStore from "../../store/userStore";
import RenderContentBlocks from "../content-block/RenderContentBlocks.vue";
const store = useCurrentUserStore();

const locations = ref([]);

const selectedLocation = ref(null);

const locationContentBlocks = ref({});

onMounted(async () => {
    if (!store.currentUser?.locations) {
        await api.fetchUserData();
    }
    locations.value = store.currentUser.locations;
    if (locations.value.length === 1) {
        selectedLocation.value = locations[0];
    }
});

watch(selectedLocation, async (location) => {
    if (location) {
        const locationId = location.id;
        if (locationContentBlocks.value[locationId]) {
            const contentBlocks = await api.getContentBlocks("operator-info", locationId);
            locationContentBlocks.value[locationId] = contentBlocks;
        }
    }
});

const selectedContentBlocks = computed(() => {
    if (!selectedLocation?.value) {
        return [];
    }
    return locationContentBlocks.value[selectedLocation.value.id];
});
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.location-select {
    width: 350px;
    padding-left: 2%;
}
</style>
