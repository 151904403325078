<template>
    <div class="filter-container">
        <div class="field-column field-column__xlarge">
            <div class="field-container">
                <span class="field-label">Date:</span>
                <date-picker v-model="range" range></date-picker>
            </div>
            <div class="field-container">
                <span class="field-label">Experience: </span>
                <v-select v-model="selectedExperience" :options="sortedExperiences" :clearable="false" label="name"></v-select>
            </div>
        </div>
        <div class="field-column field-column__xlarge">
            <div class="field-container">
                <span class="field-label">Location: </span>
                <v-select v-model="selectedLocation" :options="sortedLocations" :clearable="false" label="name">
                </v-select>
            </div>
            <div class="field-container">
                <span class="field-label">X-Cube: </span>
                <v-select v-model="selectedXCube" :options="sortedXCubes" :clearable="false" label="display">
                </v-select>
            </div>
        </div>
        <div class="field-column">
            <div class="field-container">
                <span class="field-label__large">Total Players: </span>
                <div>{{ playerSum }}</div>
            </div>

            <div class="field-container">
                <span class="field-label__large">Average Rating: </span>
                <div>{{ Number(averageRating).toFixed(1) || "N/A" }}</div>
            </div>
            <div v-if="selectedLocation.id !== 'all'" class="field-container">
                <span class="field-label__large">Average Location Rating: </span>
                <div>{{ Number(averageLocationRating).toFixed(1) || "N/A" }}</div>
            </div>
            <div v-if="selectedExperience.id !== 'all'" class="field-container">
                <span class="field-label__large">Average Playing Time: </span>
                <div>{{ Number(averagePlayingTime).toFixed(0) || "N/A" }}</div>
            </div>
        </div>
        <div class="field-column field-column__large">
            <div class="field-container">
                <span class="field-label">Options:</span>
                <v-select v-model="bookingOptions" multiple :options="BOOKING_OPTIONS"
                    :selectable="(o) => !o.includes('deleted') || bookingOptions.every((bo) => !bo.includes('deleted'))">
                </v-select>
            </div>

            <div class="field-container">
                <span class="field-label">Limit: </span>
                <v-select v-model="limit" :options="[25, 50, 100, 150, 200, 250, 1000, 2500, 10000]"
                    :clearable="false"></v-select>
            </div>
        </div>
    </div>
</template>

<script setup>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { computed, defineProps } from "vue";
import useFilterStore from "../../store/filterStore";

dayjs.extend(weekday);

const filterStore = useFilterStore();

const ALL_OPTION = {
    name: "All",
    id: "all",
    display: "All",
};

const BOOKING_OPTIONS = [
    "Only deleted",
    "Include deleted",
    "Voucher",
    "Refund",
    "Demo",
    "Consent To Share",
];

const props = defineProps({
    locations: {
        type: Array,
        default: () => [],
    },
    experiences: {
        type: Array,
        default: () => [],
    },
    playerSum: {
        type: Number,
        required: false,
    },
    averageRating: {
        type: Number,
        required: false,
    },
    averageLocationRating: {
        type: Number,
        required: false,
    },
    averagePlayingTime: {
        type: Number,
        default: null,
    },
});

const range = computed({
    get() {
        const [begin, end] = filterStore.getRange;
        return [begin.toDate(), end.toDate()];
    },
    set(newValue) {
        const [begin, end] = newValue;
        filterStore.setRange(dayjs(begin), dayjs(end));
    },
});

const selectedLocation = computed({
    get() {
        return filterStore.getSelectedLocation;
    },
    set(newValue) {
        filterStore.setSelectedLocation(newValue);
    },
});

const selectedXCube = computed({
    get() {
        return filterStore.getSelectedXCube;
    },
    set(newValue) {
        filterStore.setSelectedXCube(newValue);
    },
});

const selectedExperience = computed({
    get() {
        return filterStore.getSelectedExperience;
    },
    set(newValue) {
        filterStore.setSelectedExperience(newValue);
    },
});

const bookingOptions = computed({
    get() {
        return filterStore.getBookingOptions;
    },
    set(newValue) {
        filterStore.setBookingOptions(newValue);
    },
});

const limit = computed({
    get() {
        return filterStore.getLimit;
    },
    set(newValue) {
        filterStore.setLimit(newValue);
    },
});

const sortedLocations = computed(() => {
    return [ALL_OPTION].concat([...props.locations].sort((a, b) => a.name.localeCompare(b.name)));
});

const sortedExperiences = computed(() => {
    return [ALL_OPTION].concat(
        [...props.experiences].sort((a, b) => {
            if (a.id === "all") return -1;
            if (b.id === "all") return 1;
            return a.name.localeCompare(b.name);
        }),
    );
});

const sortedXCubes = computed(() => {
    let xcubes;
    if (selectedLocation.value?.id !== ALL_OPTION.id) {
        xcubes = selectedLocation.value.xcubes.map((x) => ({
            ...x,
            display: `${x.name} ${x.letter}`,
        }));
    } else {
        xcubes = props.locations.reduce((acc, l) => acc.concat(l.xcubes), []);
        xcubes = xcubes.map((x) => {
            const location = props.locations.find((l) => l.id === x.location);
            return {
                ...x,
                display: `${location.name} - ${x.name} ${x.letter}`,
            };
        });
    }
    const result = xcubes.toSorted((a, b) => a.name.localeCompare(b.name));
    return [ALL_OPTION, ...result];
});
</script>

<style lang="scss">
@use "../../colors" as *;

.filter-container {
    display: flex;
    justify-content: flex-start;
    background-color: rgba($color-background, 0.71);
    flex-wrap: wrap;
    border: 1px solid $color-primary;
    border-radius: 0.5rem;
    align-items: center;
    padding: 0.5rem;
}

.vs__search {
    min-width: 100px;
}

.field-column {
    flex-basis: 250px;
    min-width: 200px;
    max-width: 300px;

    &__large {
        flex-basis: 400px;
        min-width: 300px;
        max-width: 500px;

        .vs__dropdown-menu {
            min-width: 300px;
        }
    }

    &__xlarge {
        flex-basis: 500px;
        min-width: 400px;
        max-width: 600px;

        .vs__dropdown-menu {
            min-width: 400px;
        }
    }
}

.field-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem;

    .field-label {
        width: 75px;
        padding-right: 1rem;
        text-align: right;

        &__large {
            width: 175px;
        }
    }
}
</style>