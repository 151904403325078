import VueRouter from "vue-router";
import api from "../api/api";
import View from "../components/booking/View.vue";
import GeneralInformation from "../components/views/GeneralInformation.vue";
import BookingsMove from "../components/views/MoveBooking.vue";
import Bookings from "../components/views/Bookings.vue";
import BookingsCreate from "../components/views/BookingsCreate.vue";
import SiteInformation from "../components/views/SiteInformation.vue";
import { isManagement, isOperator } from "../helpers/auth";
import useCurrentUserStore from "../store/userStore";
import Confirm from "./../components/views/Confirm.vue";
import ForgotPassword from "./../components/views/ForgotPassword.vue";
import Login from "../components/views/Login.vue";
import ResetPassword from "./../components/views/ResetPassword.vue";
import Application from "./../components/views/Application.vue";
import Applications from "./../components/views/Applications.vue";
import Tools from "../components/views/Tools.vue";
import XCubeControl from "./../components/views/XCubeControl.vue";
import XCubes from "../components/views/XCubes.vue";

const routes = [
    {
        path: "/confirm/:token",
        component: Confirm,
        meta: { public: true },
    },
    {
        path: "/login",
        component: Login,
        meta: { public: true },
        name: "login",
    },
    {
        path: "/resetpassword/:token",
        component: ResetPassword,
        meta: { public: true },
        name: "ResetPassword",
    },
    {
        path: "/forgotpassword",
        component: ForgotPassword,
        meta: { public: true },
        name: "ForgotPassword",
    },
    {
        path: "/summary/:token",
        beforeEnter({ path }) {
            location.href = `${import.meta.env.VITE_APP_MY_XCUBE_URL}${path}`;
        },
        meta: { public: true },
    },
    {
        path: "/bookings",
        component: Bookings,
        name: "bookings",
        props: true,
    },
    {
        path: "/applications/",
        component: Applications,
        name: "applications",
        meta: { admin: true },
    },
    {
        path: "/applications/:application_id",
        component: Application,
        name: "application",
        meta: { admin: true },
    },
    {
        path: "/xcubes",
        component: XCubes,
        name: "xcubes",
        meta: { admin: true },
    },
    {
        path: "/tools",
        component: Tools,
        name: "tools",
        meta: { admin: true },
    },
    {
        path: "/xcubecontrol",
        component: XCubeControl,
        name: "xcube-control",
        meta: { admin: true },
    },
    {
        path: "/bookings/create",
        component: BookingsCreate,
        name: "bookings-create",
    },
    {
        path: "/bookings/move/:id",
        component: BookingsMove,
        name: "bookings-move",
    },
    {
        path: "/information/general",
        component: GeneralInformation,
        name: "general-information",
    },
    {
        path: "/information/site",
        component: SiteInformation,
        name: "site-information",
    },
    {
        path: "/bookings/view/:id",
        component: View,
        name: "view-booking",
    },
];

const router = new VueRouter({ routes, mode: "history" });

router.beforeEach(async (to, from, next) => {
    if (to.fullPath.startsWith("/#")) {
        next(to.fullPath.substring(2));
        return;
    }
    const store = useCurrentUserStore();
    if (!store.currentUser) {
        try {
            await api.fetchUserData();
        } catch (e) {
            store.setCurrentUser(null);
        }
    }
    if (!store.currentUser) {
        console.log("NO CURRENT USER");
        if (to.meta.public) {
            console.log("BUT PUBLIC PLACE SO NO PROBLEM");
            next();
        } else {
            console.log("BUT RESTRICTED PLACE SO REDIRECT TO LOGIN");
            next({ name: "login" });
        }
    } else {
        if (to.name === "login" || to.path === "/") {
            console.log("API USER", store.currentUser);
            if (isManagement(store.currentUser) || isOperator(store.currentUser)) {
                console.log("REDIRECTING USER TO BOOKINGS");
                next({ name: "bookings" });
            } else {
                console.log("REDIRECTING USER TO MY X-CUBE");
                location.href = `${import.meta.env.VITE_APP_MY_XCUBE_URL}${to.path}`;
            }
        } else if (to.meta.admin && !isManagement(store.currentUser)) {
            console.log("API USER NO ADMIN SO REDIRECT TO BOOKINGS");
            next("/bookings");
        } else {
            console.log("EVERYTHING OK");
            next();
        }
    }
});

export default router;
