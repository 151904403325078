/**
 * Writes the given string to the specified file format.
 * @param {string} textToWrite
 * @param {string} fileNameToSaveAs
 * @param {string} fileType
 * @returns void
 */
export const saveTextAsFile = (textToWrite, fileNameToSaveAs, fileType) => {
    const textFileAsBlob = new Blob([textToWrite], { type: fileType });
    const downloadLink = document.createElement("a");
    downloadLink.download = fileNameToSaveAs;
    downloadLink.innerHTML = "Download File";

    if (window.webkitURL != null) {
        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
    } else {
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
    }

    downloadLink.click();
};
