<template>
    <div>
        <table v-if="bookings && bookings.length > 0">
            <thead>
                <tr>
                    <th class="your-time-column">Time</th>
                    <th class="door-code-column">Doorcode</th>
                    <th class="booking-number-column">Bookingnumber</th>
                    <th class="client-column">Client</th>
                    <th class="team-column">Team</th>
                    <th class="players-column">Players</th>
                    <th class="email-column">Email</th>
                    <th class="language-column">Language</th>
                    <th class="minutes-played-column">Minutes Played</th>
                    <th class="experience-column">Experience</th>
                    <th class="score-column">Score</th>
                    <th class="game-state-column">Game state</th>
                    <th class="rating-column">Rating</th>
                    <th class="xcube-column">X-Cube</th>
                    <th class="booking-info-column">Booking Info</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="booking of bookings" :key="booking.id" class="content-tr">
                    <td class="your-time-column">
                        <span class="time-block-user">
                            <span v-if="
                                booking.xcube.location &&
                                !isInUserTimezone(
                                    booking.xcube.location.time_zone
                                )
                            " :class="flagClass(countryCodeBasedOnTimeZone())"></span>
                            {{ getTimeInCurrentTimeZone(booking.date) }}
                        </span>
                        <span v-if="
                            booking.xcube.location &&
                            !isInUserTimezone(
                                booking.xcube.location.time_zone
                            )
                        " class="time-block-location">
                            <span :class="flagClass(booking.xcube.location.country)
                                "></span>
                            {{
                                getTimeInTimeZone(
                                    booking.date,
                                    booking.xcube.location.time_zone
                                )
                            }}
                        </span>
                    </td>
                    <td class="door-code-column">
                        <span>{{ booking.door_code }}</span>
                    </td>
                    <td class="booking-number-column">
                        <span>{{ booking.code }}</span>
                    </td>
                    <td class="client-column">
                        <span>{{
                            booking.client && booking.client.first_name
                            }}</span>
                    </td>
                    <td class="team-column">
                        <span>{{ booking.team && booking.team.name }}</span>
                    </td>
                    <td class="players-column">
                        <span>{{ booking.player_count }}</span>
                    </td>
                    <td class="email-column">
                        <span>{{ booking.client?.email }}</span>
                    </td>
                    <td class="language-column">
                        <span>{{ booking.language }}</span>
                    </td>
                    <td class="minutes-played-column">
                        <span>{{ booking.minutes_played }}</span>
                    </td>
                    <td class="experience-column">
                        <span class="mobile-only">
                            {{ booking.team && booking.team.name }}
                        </span>
                        <span>{{
                            booking.experience && booking.experience.name
                            }}</span>
                        <span class="mobile-only">
                            {{ booking.xcube && booking.xcube.name }}
                        </span>
                    </td>
                    <td class="score-column">
                        <span>{{ booking.score }}</span>
                        <font-awesome-icon v-if="booking.finished" class="finish-flag-icon"
                            icon="fa-solid fa-flag-checkered" />
                    </td>
                    <td class="game-state-column">
                        <status-mark v-bind:checked="hasState(booking)"></status-mark>
                    </td>
                    <td class="rating-column">
                        <div v-if="booking.rating">
                            {{ booking.rating }}
                            <font-awesome-icon icon="fa-solid fa-gamepad" />
                        </div>
                        <div v-if="booking.rating_location">
                            {{ booking.rating_location }}
                            <font-awesome-icon icon="fa-solid fa-building" />
                        </div>
                    </td>
                    <td class="xcube-column">
                        <span>{{
                            booking.xcube &&
                            booking.xcube.location &&
                            booking.xcube.location.name
                            }}</span>
                        <span>
                            {{ booking.xcube && booking.xcube.name }}
                        </span>
                        <span>
                            {{ booking.xcube.letter }}
                        </span>
                    </td>
                    <td class="booking-info-column">
                        <span class="view-button" @click="showBooking(booking)">View</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else>No Bookings</div>
    </div>
</template>

<script setup>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { defineProps } from "vue";
import { useRouter } from "vue-router/composables";
import {
    countryCodeBasedOnTimeZone,
    getTimeInCurrentTimeZone,
    getTimeInTimeZone,
    isInUserTimezone,
} from "../../helpers/dates";
import StatusMark from "./../shared/StatusMark.vue";

dayjs.extend(utc);
dayjs.extend(timezone);

const router = useRouter();

const props = defineProps({
    bookings: {
        type: Array,
        default: () => [],
    },
});

const flagClass = (country) => {
    if (!country) {
        return "";
    }
    return `fi fi-${country.toLowerCase()}`;
};

const hasState = (booking) => {
    return booking.minutes_played > 5 || booking.score > 0;
};

const showBooking = (booking) => {
    router.push({
        name: "view-booking",
        params: { id: booking.id, booking: booking },
    });
};
</script>

<style scoped lang="scss">
@use "../../colors" as *;

table {
    width: 100%;
    table-layout: fixed;
    border-width: 0;

    td {
        height: 40px;
    }
}

.view-button {
    cursor: pointer;
    border: 1px solid $color-primary;
    border-radius: 5px;
    padding: 3px 5px;
}

.content-tr:hover {
    background-color: rgba($color-background, 0.2);
}

.finish-flag-icon {
    padding-left: 0.5rem;
    color: $color-info;
}

.time-column {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    .time-block-location {
        color: $color-primary;
    }
}

tr:nth-child(odd) {
    background-color: rgba($color-background, 0.8);
}

@media (max-width: 1830px) {

    .xcube-time-column,
    .door-code-column {
        display: none;
    }
}

@media (max-width: 1750px) {
    .client-column {
        display: none;
    }
}

@media (max-width: 1600px) {
    .email-column {
        display: none;
    }
}

@media (max-width: 1280px) {

    .minutes-played-column,
    .language-column {
        display: none;
    }
}

@media (max-width: 1140px) {
    .team-column {
        display: none;
    }
}

@media (max-width: 1024px) {
    .booking-number-column {
        display: none;
    }
}

@media (max-width: 768px) {

    .score-column,
    .game-state-column,
    .rating-column {
        display: none;
    }
}

@media (min-width: 512px) {
    .mobile-only {
        display: none;
    }
}

@media (max-width: 512px) {

    .players-column,
    .xcube-column {
        display: none;
    }

    .mobile-only {
        display: block;
    }
}
</style>