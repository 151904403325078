<template>
  <hub-form class="form" messageBox="absolute" v-slot:formcontent ref="hubForm" :onSubmit="savePassword">
      <h2>Enter your email</h2>
      <input placeholder="email" type="email" v-model="model.email" maxLength="64"  minLength="5" required/>
      <button @submit.prevent>Reset password</button>
      <router-link to="/login">
        Back to login
      </router-link> 
  </hub-form>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router/composables";
import api from "../../api/api";
import HubForm from "../shared/Form.vue";

const router = useRouter();
const hubForm = ref(null);

const model = ref({
    email: "",
});

const loading = ref(false);

const savePassword = async () => {
    loading.value = true;
    try {
        const res = await api.request(
            "/clients/forgot-password",
            { method: "POST" },
            {
                email: model.value.email,
            },
        );

        if (!res.ok) {
            setTimeout(() => {
                loading.value = false;
                hubForm.value.error(`Request failed, status: ${res.status}`);
            }, Math.random() * 1500);
        } else {
            loading.value = false;
            hubForm.value.success("Request sent. Please check your email.");
            router.push("/login");
        }
    } catch (error) {
        console.error(error);
        hubForm.value.error("Failed to contact the server, please try again later");
        loading.value = false;
    }
};
</script>

<style scoped lang="scss">
.form{
  background-color:black;
  display:flex;
  margin:auto;
  min-width: 340px;
  min-height: 420px;

  box-shadow: 0 0 20px black;
  border-radius: 5px;

  input{
    width:74%;
    margin:auto;
  }

  button, h2{
    width:84%;
    margin:auto;
  }

  h2{
    margin: 50px auto 10px auto;
  }

  button{
    margin: 60px auto 10px;
  }

}


.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button, .back-button {
  width: 100%;
  margin-top: 40px;
}
.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
}
</style>
