<template>
    <div class="move-booking">
        <spinner :loading="loading" text="Moving booking..."></spinner>
        <hub-form v-slot:formcontent v-if="booking" ref="form" :onSubmit="moveBooking"
            class="width-90-move center-content">
            <el-form label-width="160px">
                <template>
                    <div class="flex-container flex-center title-container">
                        <div>
                            <h2>Move Booking</h2>
                        </div>
                    </div>

                    <div class="form-wrapper">
                        <span>
                            <div class="info-wrapper">
                                <span class="info-label">Booking Number: </span>
                                <span class="info-value">{{
                                    booking.code
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Email: </span>
                                <span class="info-value">{{
                                    booking.client.email
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Phone number: </span>
                                <span class="info-value">{{
                                    booking.client.phone_number
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Location: </span>
                                <span class="info-value">{{
                                    booking.xcube.location.name
                                    }}</span>
                            </div>

                            <div class="info-wrapper">
                                <span class="info-label">X-Cube Name: </span>
                                <span class="info-value">{{
                                    booking.xcube.name
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Team name</span>
                                <span class="info-value">{{
                                    booking.team.name
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Player Count</span>
                                <span class="info-value">{{
                                    booking.player_count
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Language</span>
                                <span class="info-value">{{
                                    booking.language
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Experience</span>
                                <span class="info-value">{{
                                    booking.experience.name
                                    }}</span>
                            </div>

                            <el-form-item label="New Event Date">
                                <div class="input-with-text">
                                    <date-picker v-model="formData.event_date" :confirm="true" type="date"
                                        format="DD-MM-YYYY" placeholder="Select date" required />
                                </div>
                            </el-form-item>
                            <el-form-item label="New Event Time">
                                <div class="input-with-text">
                                    <date-picker v-model="formData.event_time" :confirm="true" type="time" format="HH:mm"
                                        placeholder="Select time" required />
                                </div>
                                <span class="text-next-to-input">Timezone: {{ getTimezoneName() }}</span>
                            </el-form-item>

                            <el-form-item label="">
                                <el-button @click="back">
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                                </el-button>
                                <el-button @click="moveBooking">Move</el-button>
                            </el-form-item>
                        </span>
                    </div>
                </template>
            </el-form>
        </hub-form>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import { Message } from "element-ui";

import api from "../../api/api";
import HubForm from "../shared/Form.vue";
import Spinner from "../shared/Spinner.vue";

const router = useRouter();
const route = useRoute();
const form = ref(null);

const loading = ref(false);
const movedBooking = ref(null);
const formData = ref({
    event_date: new Date(),
    event_time: new Date(),
});
const booking = ref(null);

const getTimezoneName = () => {
    // Get the current timezone name
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezoneName;
};

const back = () => {
    router.go(-1);
};

const moveBooking = async () => {
    const time = dayjs(formData.value.event_time);
    const code = `HUB-${new Date().getTime()}`;
    console.log("FORM", formData.value.event_date);
    const eventDayjs = dayjs(formData.value.event_date).hour(time.hour()).minute(time.minute());
    if (eventDayjs.isBefore(dayjs())) {
        Message.error({
            message: "Date/time should be in the future",
        });
        return;
    }
    const event_datetime = eventDayjs.toDate();

    const data = {
        code,
        event_datetime,
        booking_id: code,
        language: booking.value.language,
        name: `${booking.value.client.first_name} ${booking.value.client.last_name}`,
        email: booking.value.client.email,
        team_1_size: booking.value.player_count,
        team_1_name: booking.value.team.name,
        booking_datetime: new Date(),
        experience_id: Number.parseInt(booking.value.experience.id),
        xcube_id: Number.parseInt(booking.value.xcube.id),
        experienceName: booking.value.experience.name,
    };
    try {
        loading.value = true;
        const newBooking = await api.moveBooking(booking.value, data);
        loading.value = false;
        Message.success({ message: "Booking has been moved" });
        router.push({
            name: "view-booking",
            params: { id: newBooking.id },
        });
    } catch (e) {
        loading.value = false;
        if (e.message === "conflict") {
            Message.error({
                message: "There is already another booking on this time",
            });
        } else {
            Message.error({
                message: `Error from server : ${e.message}`,
            });
            throw e;
        }
    }
};

/**
 * Method that hides the date picker table so that it doesn't mess up the time picker table.
 * Sets the attribute hidden once this method has been triggered.
 * There is no need to remove the hidden attribute because when a new date picker is opened it's a completely new render.
 */
const toggleDatePickerTable = () => {
    const tables = document.getElementsByClassName("mx-table");
    if (tables.length > 0) {
        tables[0].setAttribute("hidden", "true");
    }
};

onMounted(async () => {
    booking.value = await api.fetchBookingData(route.params.id);
    formData.value.event_date = new Date(booking.value.date);
    formData.value.event_time = new Date(booking.value.date);
});
</script>

<style lang="scss" scoped>
@use "../../colors" as *;

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    form.el-form {
        width: 100%;
    }
}

.info-wrapper {
    display: flex;
    padding-bottom: 12px;

    .info-label {
        width: 148px;
        text-align: right;
        padding-right: 12px;
    }

    .info-value {
        text-align: left;
    }
}

@media screen and (max-width: 800px) {
    .info-wrapper {
        flex-direction: column;
        align-items: center;

        .info-label {
            width: unset;
        }
    }
}
</style>