<template>
  <span v-if="datetime">{{formattedDateTime}}</span>
  <span v-else-if="date">{{formattedDate}}</span>
  <span v-else-if="time">{{formattedTime}}</span>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
    datetime: String,
    date: String,
    time: String,
});

const formattedDateTime = computed(() => {
    return Intl.DateTimeFormat("nl-NL", {
        dateStyle: "short",
        timeStyle: "short",
    }).format(new Date(props.datetime));
});

const formattedDate = computed(() => {
    return Intl.DateTimeFormat("nl-NL", {
        year: "numeric",
        month: "long",
        day: "numeric",
    }).format(new Date(props.date));
});

const formattedTime = computed(() => {
    return Intl.DateTimeFormat("nl-NL", {
        timeStyle: "short",
    }).format(new Date(props.time));
});
</script>

<style>

</style>