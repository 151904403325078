import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { defineStore } from "pinia";

dayjs.extend(weekday);

const ALL_OPTION = {
    name: "All",
    id: "all",
    display: "All",
};

const state = () => {
    const today = dayjs();
    let startDate = today.weekday(1);
    let endDate = today.weekday(7);
    if (today.weekday() === 0) {
        startDate = today.weekday(-6);
        endDate = today.weekday(0);
    }

    return {
        filter: {
            range: [startDate, endDate],
            selectedLocation: ALL_OPTION,
            selectedXCube: ALL_OPTION,
            selectedExperience: ALL_OPTION,
            bookingOptions: [],
            limit: 1000,
        },
    };
};

const getters = {
    getRange: (state) => state.filter.range,
    getSelectedLocation: (state) => state.filter.selectedLocation,
    getSelectedXCube: (state) => state.filter.selectedXCube,
    getSelectedExperience: (state) => state.filter.selectedExperience,
    getBookingOptions: (state) => state.filter.bookingOptions,
    getLimit: (state) => state.filter.limit,
    filters: (state) => {
        return {
            startDate: state.filter.range[0].format("YYYY-MM-DD"),
            endDate: state.filter.range[1].format("YYYY-MM-DD"),
            locationId: state.filter.selectedLocation.id,
            xCubeId: state.filter.selectedXCube.id,
            experienceId: state.filter.selectedExperience.id,
            options: [...state.filter.bookingOptions],
            limit: state.filter.limit,
        };
    },
};

const actions = {
    setRange(startDate, endDate) {
        this.filter.range = [startDate, endDate];
    },
    setSelectedLocation(location) {
        this.filter.selectedLocation = location;
    },
    setSelectedXCube(xCube) {
        this.filter.selectedXCube = xCube;
    },
    setSelectedExperience(experience) {
        this.filter.selectedExperience = experience;
    },
    setBookingOptions(options) {
        this.filter.bookingOptions = [...options];
    },
    setLimit(limit) {
        this.filter.limit = limit;
    },
};

const useFilterStore = defineStore("filter", {
    state,
    getters,
    actions,
});

export default useFilterStore;
