import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "apollo-boost";
import queryString from "query-string";
import useCurrentUserStore from "../store/userStore";
import applicationQuery from "./queries/application";
import applicationsQuery from "./queries/applications";
import bookingQuery from "./queries/booking";
import userQuery from "./queries/user";
import xcubesQuery from "./queries/xcubes";

// You should use an absolute URL here
const httpLink = new HttpLink({
    uri: `${import.meta.env.VITE_APP_API_URL}/graphql`,
});

const errorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        if (response.errors?.find((error) => error.message.toLowerCase() === "invalid token.")) {
            logout();
            throw new Error("Your token has expired. Please login again.");
        }
        return response;
    });
});

const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: new InMemoryCache(),
});

function getToken() {
    return localStorage.getItem("xcube-session-key");
}

function parseJwt() {
    const token = getToken();
    if (!token) return {};
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const repl = (c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(repl).join(""));
    return JSON.parse(jsonPayload);
}

function getApolloOptions() {
    return {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        context: {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        },
    };
}

const defaultOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
    referrerPolicy: "no-referrer",
};
const HOST = import.meta.env.VITE_APP_API_URL;

const getBookingTable = async (params = undefined) => {
    const path = `/bookings?${params ? queryString.stringify(params) : ""}`;
    const options = getOptions();
    const res = await request(path, options);
    const data = await res.json();
    if (data.statusCode >= 400) {
        throw new Error(data.message);
    }
    return data;
};

const getBooking = async (id) => {
    if (!id) {
        throw new Error("No id specified");
    }
    const path = `/bookings/${id}`;
    const options = getOptions();
    const res = await request(path, options);
    const data = await res.json();
    if (data.statusCode >= 300) {
        throw new Error(data.message);
    }
    return data;
};

const getUserLocations = async (params = undefined) => {
    const path = `/locations/mine${params ? queryString.stringify(params) : ""}`;
    const options = getOptions();
    const res = await request(path, options);
    const locations = await res.json();
    return locations;
};

const getXCubes = async () => {
    const path = "/xcubes?_sort=name";
    const options = getOptions();
    const res = await request(path, options);
    const xCubes = await res.json();
    // TODO this is only necessary since location is not mandatory
    // for a xcube and there are some xcubes without a location.
    // Once this is fixed this can be removed
    const cleanedXCubes = xCubes.map((xc) => {
        if (xc.location) {
            return xc;
        }
        return {
            ...xc,
            location: {
                name: "?",
                id: null,
                time_zone: "?",
                address: "?",
                zipcode: "?",
            },
        };
    });
    return cleanedXCubes;
};

const getXCubesSimple = async () => {
    const res = await apolloClient.query({
        ...getApolloOptions(),
        query: xcubesQuery,
    });

    return res.data.xcubes;
};

const getExperiences = async () => {
    const path = "/experiences?_sort=name";
    const res = await request(path, getOptions());
    return await res.json();
};

const getContentBlocks = async (key, locationId = null) => {
    let path = `/content-blocks?key=${key}`;
    if (locationId != null) {
        path += `&location.id=${locationId}`;
    } else {
        path += "&location.id_null=1";
    }
    const options = getOptions();
    const res = await request(path, options);
    return await res.json();
};

const createBooking = async (booking) => {
    const path = "/api/bookings";
    const res = await request(
        path,
        getOptions({
            method: "POST",
            body: JSON.stringify(booking),
        }),
    );
    if (!res.ok) {
        const text = await res.text();
        if (
            res.status === 400 &&
            text.toLowerCase().includes("one or more bookings at this time already exist")
        ) {
            throw new Error("conflict");
        }
        throw new Error(text);
    }
    return res.json();
};

const deleteBooking = async (booking) => {
    const deleted_at = new Date().toISOString();
    const result = await updateBooking(booking.id, { deleted_at });
    return {
        ...booking,
        deleted_at: result.deleted_at,
    };
};

const moveBooking = async (originalBooking, newBooking) => {
    const createdBooking = await createBooking(newBooking);
    await deleteBooking(originalBooking);
    return createdBooking;
};

const updateXCubeApplication = async (id, xCubeApp) => {
    const path = `/x-cube-applications/${id}`;
    const res = await request(
        path,
        getOptions({
            method: "PUT",
            body: JSON.stringify(xCubeApp),
        }),
    );
    return {
        json: await res.json(),
        status: res.status,
    };
};

const updateXCube = async (id, xCube) => {
    const path = `/xcubes/${id}`;
    const res = await request(
        path,
        getOptions({
            method: "PUT",
            body: JSON.stringify(xCube),
        }),
    );
    return {
        json: await res.json(),
        status: res.status,
    };
};

const getHeaders = () => {
    const token = getToken();

    const headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return headers;
};

const getOptions = (overwrites = {}) => {
    const options = Object.assign({}, defaultOptions);
    options.headers = getHeaders();
    return Object.assign({}, options, overwrites);
};

const request = async (path, options = {}, data = undefined) => {
    if (data) options.body = JSON.stringify(data);
    const newOptions = getOptions(options);
    const response = await fetch(HOST + path, newOptions);
    if (response.status === 403 || response.status === 401) {
        logout();
        throw new Error("Invalid Token");
    }
    return response;
};

const login = async (credentials) => {
    const path = "/auth/local";
    const options = getOptions({ method: "POST" });

    const result = await request(path, options, credentials);
    const json = await result.json();

    if (result.status !== 200) {
        throw new Error("Unknown username/password");
    }
    localStorage.setItem("xcube-session-key", json.jwt);
    await fetchUserData();
    return { status: result.status, role: json.user.role };
};

const checkConfirmToken = async (token) => {
    const options = getOptions({ method: "GET" });
    const path = `/clients/isVerified?activation_token=${token}`;
    const response = await fetch(HOST + path, options);

    if (response.ok) {
        const { isVerified } = await response.json();
        return isVerified;
    }
    return false;
};

const logout = async () => {
    localStorage.removeItem("xcube-session-key");
    const store = useCurrentUserStore();
    store.setCurrentUser(null);
    window.location.reload();
};

const fetchUserData = async () => {
    if (!getToken()) {
        return null;
    }
    const { id } = parseJwt();
    if (!id) {
        return null;
    }
    const res = await apolloClient.query({
        ...getApolloOptions(),
        query: userQuery,
        variables: { id },
    });
    const store = useCurrentUserStore();
    store.setCurrentUser(res.data.user);
    return res.data.user;
};

const fetchBookingData = async (id) => {
    const res = await apolloClient.query({
        ...getApolloOptions(),
        query: bookingQuery,
        variables: { id },
    });
    return res.data.booking;
};

const getApplications = async () => {
    const res = await apolloClient.query({
        ...getApolloOptions(),
        query: applicationsQuery,
    });

    return res.data.applications;
};

const getApplication = async (id) => {
    const res = await apolloClient.query({
        ...getApolloOptions(),
        query: applicationQuery,
        variables: { id },
    });

    return res.data.application;
};

const getApplicationRest = async (id) => {
    const path = `/applications/${id}`;
    const options = getOptions();
    const result = await request(path, options);
    return await result.json();
};

// const getBookings = async () => {
//   const path = "/bookings/next-bookings";
//   let options = getOptions();
//   const result = await request(path, options);
//   return result;
// };

const updateBooking = async (id, booking) => {
    const path = `/bookings/${id}`;
    const res = await request(
        path,
        getOptions({
            method: "PUT",
            body: JSON.stringify(booking),
        }),
    );
    return await res.json();
};


const finishBooking = async (id) => {
    const path = `/bookings/${id}/finish`;
    const res = await request(
        path,
        getOptions({
            method: "PATCH",
            body: JSON.stringify({}),
        }),
    );
    return await res.json();
};

const getCustomersCSV = async () => {
    const path = "/export/customers";
    const res = await request(
        path,
        getOptions({
            method: "GET",
        }),
    );
    return res.json();
};

export default {
    getBookingTable,
    getBooking,
    getUserLocations,
    getXCubes,
    getXCubesSimple,
    getExperiences,
    getContentBlocks,
    createBooking,
    deleteBooking,
    moveBooking,
    updateXCubeApplication,
    updateXCube,
    request,
    login,
    checkConfirmToken,
    logout,
    fetchUserData,
    fetchBookingData,
    getApplications,
    getApplication,
    getApplicationRest,
    updateBooking,
    getCustomersCSV,
    finishBooking,
};
