<template>
    <div class="xcubes-table-wrapper">
      <div class="xcubes-table-container">
        <x-cubes-table :xcubes="xcubes" class="xcube-table" />
      </div>
    </div>
  </template>
  
  <script setup>
import { ref } from "vue";
import XCubesTable from "./Table.vue";

defineProps({
    xcubes: {
        type: Array,
        default: () => [],
    },
});

const nextBookings = ref([]);
const lastBookings = ref([]);
const filters = ref(null);
const selectedTab = ref(1);
const totalPlayers = ref(0);
const averageRating = ref(0);
</script>
  
  <style scoped lang="scss">
  .xcubes-table-wrapper {
    position: absolute;
    left: 2vw;
    top: 2vh;
    width: calc(100% - 4vw);
    height: calc(100% - 4vh);
  }
  
  .xcubes-table-container {
    display: flex;
    flex-direction: column;
    padding-top:1rem;
  }
  
    .xcubes-table {
      // flex: 1 1 320px;
  
      border: 1px solid #e8de16;
  
      &:not(.selected) {
        display: none;
      }
  
      &:nth-child(1) {
        border-radius: 0 0 0 10px;
      }
  
      &:nth-child(2) {
        border-radius: 0 0 10px 0;
      }
  }
  
  @media (min-device-width: 1440px) and (max-device-width: 1872px) {
    .tables {
      font-size: 0.85vw;
    }
  }
  </style>