<template>
    <hub-form v-slot:formcontent ref="hubForm" :onSubmit="savePassword">
      <input placeholder="password" type="password" v-model="model.password" maxLength="32"  minLength="5" required/>
      <input placeholder="confirm password" type="password" v-model="model.confirmPassword" maxLength="32"  minLength="5" required/>
      <button @submit.prevent>Reset password</button>  
    </hub-form>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import api from "../../api/api";
import HubForm from "../shared/Form.vue";

const router = useRouter();
const route = useRoute();
const hubForm = ref(null);

const token = route.params.token;
const model = ref({
    code: token,
    password: "",
    confirmPassword: "",
});

const savePassword = async () => {
    if (model.value.password !== model.value.confirmPassword) {
        hubForm.value.error("Passwords do not match");
        return;
    }

    try {
        const result = await api.request(
            "/auth/reset-password",
            { method: "POST" },
            {
                code: token,
                password: model.value.password,
                passwordConfirmation: model.value.password,
            },
        );

        hubForm.value.res = result.status;

        if (result.status >= 200 && result.status < 299) {
            hubForm.value.success("Your password has been updated.");
            const res = await result.json();
            const jwt = res.jwt;
            router.push({ name: "booking", params: { jwt } });
        } else {
            hubForm.value.error("Could not change password, please contact support.");
        }
    } catch (error) {
        console.error("Reset password error:", error);
        hubForm.value.error("An error occurred while resetting your password.");
    }
};
</script>

<style scoped>

</style>
