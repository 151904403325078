<template>
    <booking-overview
        :locations="locations"
        :experiences="experiences"
        :past="past"
    />
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router/composables";
import api from "../../api/api";
import BookingOverview from "../booking/Overview.vue";

const locations = ref([]);
const experiences = ref([]);

const route = useRoute();
const past = computed(() => route.query.past === "true");

const updateLocations = async () => {
    locations.value = await api.getUserLocations();
};

const updateExperiences = async () => {
    experiences.value = await api.getExperiences();
};

onMounted(() => {
    updateLocations();
    updateExperiences();
});
</script>

<style>
</style>