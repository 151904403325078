<template>
    <div>
        <x-cube-overview v-if="!loading" :xcubes="items" />
        <spinner :loading="loading" text="Loading XCubes ..."></spinner>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import api from "../../api/api";
import Spinner from "../shared/Spinner.vue";
import XCubeOverview from "../xcube/Overview.vue";

const items = ref([]);
const loading = ref(false);
let intervalId;

const fetchItems = async () => {
    loading.value = true;
    items.value = await api.getXCubes();
    loading.value = false;
    intervalId = setInterval(async () => {
        items.value = await api.getXCubes();
    }, 5000);
};

onMounted(() => {
    fetchItems();
});

onBeforeUnmount(() => {
    if (intervalId) {
        clearInterval(intervalId);
    }
});
</script>

<style>
</style>