<template>
    <div class="container">
      <div v-if="!submitted" class="form-container">
        <div :class="`message-box ${messageStr ? '' : 'hidden'} ${MESSAGE_TYPES[messageType]} ${MESSAGE_BOX_CLASS[$props.messageBox]}`">
          <!--<span>&#9888;</span>-->
          <span class="warning-icon">{{ messageStr }}</span>
        </div>
        <form ref="form" v-on:submit.prevent="">
          <slot name="formcontent"></slot>
        </form>
      </div>
      <div v-else class="submitted-container">
        {{ messageStr }}
      </div>
    </div>
  </template>
  
  <script setup>
import * as Sentry from "@sentry/vue";
import { defineProps, onBeforeUnmount, onMounted, ref, watch } from "vue";
import MESSAGE_TYPES from "../../constants/messageTypes";

const MESSAGE_BOX_CLASS = {
    none: "none",
    "above-form": "above-form",
    absolute: "absolute",
};

const props = defineProps({
    onSubmit: {
        type: Function,
        default: null,
    },
    messages: {
        type: Object,
        default: () => ({}),
    },
    messageBox: {
        type: String,
        default: "above-form",
    },
});

const form = ref(null);
const defaultError = ref("An error occurred, please try again later");
const messageStr = ref("");
const messageType = ref(MESSAGE_TYPES.message);
const submitted = ref(false);
const res = ref(null);

const getMessage = (result) => {
    // Checks for direct result in the object
    if (props.messages?.[result]) {
        return props.messages[result];
    }

    // Checks if result falls in between ranges
    const rangeRes = Object.entries(props.messages)
        .filter(([k]) => {
            const range = k.split("-");
            if (range.length !== 2) return false;
            const [min, max] = range;
            return result >= Number.parseInt(min) && result <= Number.parseInt(max);
        })
        .map(([, v]) => v)[0];

    if (rangeRes) {
        return rangeRes;
    }

    // Default messages
    if (result >= 200 && result < 300) {
        return "Success";
    }
    if (result >= 400) {
        return defaultError.value;
    }
    return result;
};

const submit = async () => {
    // Pre-submit
    if (!form.value.checkValidity()) {
        // submit an invalid form for built-in html popups (won't actually submit)
        form.value.submit();
        return;
    }

    try {
        if (props.onSubmit) {
            await props.onSubmit();
        }
    } catch (e) {
        res.value = "default";
        console.error(e);
        Sentry.captureException(e);
    }
};

const message = (str) => {
    messageType.value = MESSAGE_TYPES.message;
    messageStr.value = str;
};

const success = (str) => {
    messageType.value = MESSAGE_TYPES.success;
    messageStr.value = str;
};

const error = (str) => {
    messageType.value = MESSAGE_TYPES.error;
    messageStr.value = str;
};

watch(res, (newVal) => {
    if (!newVal) {
        messageStr.value = "";
        return;
    }

    const msg = getMessage(newVal);
    if (newVal >= 200 && newVal < 300) {
        success(msg);
    } else if (newVal >= 400) {
        error(msg);
    } else {
        message(msg);
    }
});

onMounted(() => {
    if (form.value) {
        form.value.addEventListener("submit", submit);
    }
});

onBeforeUnmount(() => {
    if (form.value) {
        form.value.removeEventListener("submit", submit);
    }
});

defineExpose({
    message,
    success,
    error,
});
</script>
  
<style lang="scss" scoped>
    form:not(.el-form) {
        padding: 10px;
        background: rgba(0,0,0,0.4);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        .el-input,
        .el-select {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    
        input {
            height: 35px;
            margin: 5px;
            padding-left: 15px;
            padding-right: 15px;
        
            background-color: #ffffff24;
            border: 1px solid yellow;
            border-radius: 5px;
        
            font-size: 13px;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
        
            &::placeholder {
                color: #ffffff5c;
                opacity: 1;
            }
        
            &:focus {
                outline: none;
            }
        }
    
        button,
        input[type="submit"] {
            height: 35px;
            margin: 5px;
            padding-left: 15px;
            padding-right: 15px;
        
            color: black;
            background-color: yellow;
            border: 1px solid yellow;
            border-radius: 5px;
            outline: none;
        
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
    
    .form-container {
        width: 100%;
        height: 100%;
    }
    
    .message-box {
        background-color: #007c89;
        color: #00e7ff;
    
        min-height: 50px;
        margin: 20px 0;
    
        border-radius: 5px;
        border: 1px solid #00e7ff;
    
        display: flex;
        
        &.hidden {
            visibility: hidden;
        }
        
        > span {
            margin: auto;
            width: calc(100% - 80px); /* because the icon is 40px absolute, so that the text is centered better*/
        }
        
        &.none {
            display: none;
        }
        
        &.absolute {
            position: absolute;
            margin: 5px;
            top: 0;
            left: 30%;
            right: 30%;
        }
        
            /* MessageBox Types*/
        &.error {
            background-color: #780116;
            color: #ff002d;
            border: 1px solid #ff002d;
            }
        &.error::before {
            content: "\26A0";
            margin: auto 10px;
            font-size: 30px;
            position: absolute;
        }
        
        &.message {
            background-color: #007c89;
            color: #00e7ff;
            border: 1px solid #00e7ff;
        }
        &.message::before {
            content: "\1F6C8";
            margin: auto 10px;
            font-size: 30px;
            position: absolute;
        }
        
        &.success {
            background-color: #044400;
            color: #10ff00;
            border: 1px solid #10ff00;
        }
        &.success::before {
            content: "\2714";
            margin: auto 10px;
            font-size: 30px;
            position: absolute;
        }
    }
</style>