<template>
  <div>
    <h1>General Information</h1>
    <render-content-blocks :content-blocks="contentBlocks" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import api from "../../api/api";
import RenderContentBlocks from "../content-block/RenderContentBlocks.vue";

const contentBlocks = ref([]);

onMounted(async () => {
    contentBlocks.value = await api.getContentBlocks("operator-info");
});
</script>

<style></style>
