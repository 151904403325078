<template>
    <div>
        <div class="menu-container" v-if="!loading">
            <h2 class="width-90">Only use with permission from X-Corp</h2>
            <p>
                This tool can be used to restart the host application on your
                x-cube.<br />
                <b>Required:</b> Your cube needs a functional internet
                connection.<br />
                <br />
                Use in case:
            </p>
            <ul>
                <li>The main screen does not respond to touch</li>
                <li>You started the game in the wrong language</li>
            </ul>
            <v-select
                    v-model="selectedItem"
                    class="custom-select"
                    :options="displayItems"
                    :clearable="true"
                    label="display"
                ></v-select>

            <!-- <select
                v-model="selectedItem"
                :disabled="loading"
                class="custom-select"
            >
                <option value="">Select X-CUBE from this list</option>
                <option v-for="item in items" :key="item.id" :value="item">
                    {{ item.name }} {{ item.location.name }}
                </option>
            </select> -->
            <div class="button-container width-90" v-if="selectedItem">
                <p>Selected X-CUBE: {{ selectedItem.name }}</p>
                <button @click="showConfirmation = true">
                    Restart HOST {{ selectedItem.name }}
                </button>
                <h1 v-if="restartRequested">
                    Restart Requested for {{ selectedItem.name }}!
                </h1>
            </div>
            <div v-if="showConfirmation" class="custom-dialog">
                <p>
                    Are you sure you want to restart the
                    {{ selectedItem.name }} X-CUBE?
                </p>
                <button @click="handleConfirmRestart">
                    YES, RESTART SELECTED X-CUBE
                </button>
                <button class="cancel" @click="showConfirmation = false">
                    NO, PLEASE CANCEL!
                </button>
            </div>
        </div>
        <spinner
            :loading="loading"
            text="Loading list of available X-Cubes..."
        ></spinner>
    </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import api from "../../api/api";
import Spinner from "../shared/Spinner.vue";

const items = ref([]);
const selectedItem = ref("");
const loading = ref(false);
const requestRestart = ref(false);
const restartRequested = ref(false);
const showConfirmation = ref(false);
let intervalId;

const displayItems = computed(() => {
    return items.value.map((x) => ({
        ...x,
        display: `${x.location.name} - ${x.name} ${x.letter || ""}`,
    }));
});

const restartXCube = async () => {
    selectedItem.value = null;
    fetchItems();
};

const fetchItems = async () => {
    loading.value = true;
    intervalId = setInterval(async () => {
        items.value = await api.getXCubes();
    }, 5000);
    items.value = await api.getXCubes();
    loading.value = false;
};

const handleRestart = async () => {
    showConfirmation.value = true;
};

const handleConfirmRestart = async () => {
    const confirmed = confirm("WARNING! MAKE SURE NO GAME OR EXPERIENCE IS IN PROGRESS!");
    if (confirmed) {
        console.log(">> Restart requested!");
        const request_restart = true;
        console.log(">>", selectedItem.value.id, request_restart);
        await api.updateXCube(selectedItem.value.id, {
            request_restart,
        });

        restartRequested.value = true;
        setTimeout(() => {
            restartRequested.value = false;
        }, 15000);
    }

    showConfirmation.value = false;
};

onMounted(() => {
    fetchItems();
    selectedItem.value = "";
});

onBeforeUnmount(() => {
    if (intervalId) {
        clearInterval(intervalId);
    }
});
</script>

<style>
.menu-container {
    margin-top: 50px;
}

.custom-select {
    width: 50%;
    /* height: 30px;
    padding: 6px 12px;
    font-size: 15px;
    color: #000000;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px; */
}

.button-container {
    margin-top: 50px;
}

.width-90 {
    width: 50% !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.custom-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
}

.custom-dialog p {
    margin: 0 0 15px;
}

.custom-dialog button {
    margin: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.custom-dialog button.cancel {
    background-color: #ff0000;
    color: #fff;
}

.custom-dialog button:hover {
    text-decoration: underline;
}

.menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .width-90,
    .custom-select {
        width: 90%;
    }
}
</style>