<template>
    <div>
        <template v-if="isManagementUser">
            <h1>Tools</h1>
            <h3>Customer Data</h3>
            <el-button @click="downloadCSV">Download</el-button>
        </template>
        <template v-else>
            <h1>Unauthorized access</h1>
        </template>
    </div>
</template>


<script setup>
import { computed } from "vue";
import api from "../../api/api";
import { isManagement } from "../../helpers/auth";
import { saveTextAsFile } from "../../helpers/saveTextasFile";
import useCurrentUserStore from "../../store/userStore";

const store = useCurrentUserStore();

const downloadCSV = async () => {
    const result = await api.getCustomersCSV();
    saveTextAsFile(result.csvData, "customer_data.csv", "csv");
};

// const userRoleName = computed(() => {
//             return (api && api.user && api.user.role && api.user.role.name) || "";
//         });

const isManagementUser = computed(() => {
    return isManagement(store.currentUser);
});
</script>