import { defineStore } from "pinia";

const state = () => {
    return {
        storedUser: null,
    };
};

const getters = {
    currentUser: (state) => state.storedUser,
};

const actions = {
    setCurrentUser(user) {
        this.storedUser = user;
    },
};

const useCurrentUserStore = defineStore("currentUser", {
    state,
    getters,
    actions,
});

export default useCurrentUserStore;
