<template>
    <booking-create :locations="locations" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import api from "../../api/api";
import BookingCreate from "../booking/Create.vue";

const locations = ref([]);

onMounted(async () => {
    locations.value = await api.getUserLocations();
});
</script>

<style>
</style>