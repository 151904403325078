import { useRouter } from "vue-router/composables";
import api from "../api/api";

export const logout = () => {
    api.logout();
    const router = useRouter();
    router.push("/login");
};

export const isTestEnvironment = () => !import.meta.env.PROD;

export const isManagement = (user) => user?.role?.name === "X-Cube Management";

export const isOperator = (user) => user?.role?.name === "X-Cube Operator";
